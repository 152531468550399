import React from 'react';
import HeadLine from './header/HeadLine'
import QnA from './header/QnA'

function Header() {
    return (
    <div className='Header'>
        <HeadLine></HeadLine>
        <QnA></QnA>
    </div>);
}

export default Header;
import React from 'react';
import imgMascot from '../../assets/img/lunch-mascot.png';
import './Main.css';

function Main() {
    return (
    <div className="lunchapp-main">
        <div className="lunch-item-container">
        <div className="lunch-item">
            <div className="category">
            요일별
            <div className="desc">
            텐션에 따라 밥도 다르다
            </div>
            </div>
            <ul className="choice-list">
                <li>
                    <div className="choice">
                    진 빠지는
                    <span>월요일</span>
                    </div>
                    <div className="option1">
                    콩나물해장국
                    <span>#기운내요</span>
                    </div>
                    <div className='option2'>
                    편의점도시락
                    <span>#먹고한숨잘래</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    애매한
                    <span>화요일</span>
                    </div>
                    <div className="option1">
                    순대국
                    <span>#푸짐하다</span>
                    </div>
                    <div className='option2'>
                    자장면
                    <span>#짜장면</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    괜히 금요일 같은
                    <span>수요일</span>
                    </div>
                    <div className="option1">
                    제육볶음
                    <span>#코리안클래식</span>
                    </div>
                    <div className='option2'>
                    생선구이
                    <span>#건강해지는기분</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    일주일이 길다
                    <span>목요일</span>
                    </div>
                    <div className="option1">
                    백반
                    <span>#건강해지는기분</span>
                    </div>
                    <div className='option2'>
                    낙지볶음
                    <span>#스트레스빠염</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    둠칫둠칫
                    <span>금요일</span>
                    </div>
                    <div className="option1">
                    우거지해장국
                    <span>#어제달림</span>
                    </div>
                    <div className='option2'>
                    돈가스
                    <span>#사이드냉모밀</span>
                    </div>
                </li>
            </ul>
        </div>

        <div className="lunch-item">
            <div className="category">
            날씨별
            <div className="desc">
            비 오면 파전? <br />
            점심으론 좀 그래요
            </div>
            </div>
            <ul className="choice-list">
                <li>
                    <div className="choice">
                    좋다
                    <span>맑다</span>
                    </div>
                    <div className="option1">
                    비빔밥
                    <span>#상쾌하다</span>
                    </div>
                    <div className='option2'>
                    차돌된장찌개
                    <span>#옳다</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    저런
                    <span>비온다</span>
                    </div>
                    <div className="option1">
                    칼국수
                    <span>#만두추가</span>
                    </div>
                    <div className='option2'>
                    짬뽕
                    <span>#뜨끈뜨끈</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    별로다
                    <span>덥다</span>
                    </div>
                    <div className="option1">
                    삼계탕
                    <span>#이열치열</span>
                    </div>
                    <div className='option2'>
                    물회
                    <span>#차가운거</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    아오
                    <span>춥다</span>
                    </div>
                    <div className="option1">
                    부대찌개
                    <span>#맘이든든</span>
                    </div>
                    <div className='option2'>
                    쌀국수
                    <span>#호로로록</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    헤헤
                    <span>날씨좋다</span>
                    </div>
                    <div className="option1">
                    김밥
                    <span>#산책하자</span>
                    </div>
                    <div className='option2'>
                    샌드위치
                    <span>#피크닉기분</span>
                    </div>
                </li>
            </ul>
        </div>

        <div className="lunch-item">
            <div className="category">
            동행별
            <div className="desc">
            체하지 말아요 우리
            </div>
            </div>
            <ul className="choice-list">
                <li>
                    <div className="choice">
                    <span>윗분이랑</span>
                    </div>
                    <div className="option1">
                    초밥
                    <span>#덜체해요</span>
                    </div>
                    <div className='option2'>
                    #그냥윗분좋아하는메뉴
                    </div>
                </li>
                <li>
                    <div className="choice">
                    <span>옆팀이랑</span>
                    </div>
                    <div className="option1">
                    규동
                    <span>#대화에집중</span>
                    </div>
                    <div className='option2'>
                    닭갈비
                    <span>#지글지글한분위기</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    <span>맨날 먹는 애랑</span>
                    </div>
                    <div className="option1">
                    뼈해장국
                    <span>#평타는침</span>
                    </div>
                    <div className='option2'>
                    분식파티
                    <span>#워후</span>
                    </div>
                </li>
                <li>
                    <div className="choice">
                    <span>신입이랑</span>
                    </div>
                    <div className="option1">
                    만두전골
                    <span>#MZ니까 #ManduZungol</span>
                    </div>
                </li>
            </ul>
        </div>
        </div>

        <div className="lunch-item michelin">
            <div className="category">
                <div className="title">
                    <div>미슐랭 선정</div>
                    <div>서울 가성비 맛집</div>
                    <div>빕 구르망</div>
                </div>
            <div className="desc-container">
                <div className="desc">
                미슐랭이 미쉐린 타이어인거<br />
                나만 최근에 알았어요?
                </div>
                <div className="img-mascot-container">
                    <img src={imgMascot} alt="미쉐린 마스코트" />
                </div>
            </div>
            </div>
            <div id="embed-map">
                <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Tb9OVlWaTl2CrISjUG2xL6qLXOHunUI&ehbc=2E312F" width="640" height="480"></iframe>
            </div>
        </div>
        
    </div>);
}

export default Main;
/* eslint-disable no-restricted-globals */
import React from 'react';
import './SkillStack.css';
import langC from '../../../assets/img/lang-c.png';
import langJava from '../../../assets/img/lang-java.png';
import langPython from '../../../assets/img/lang-python.png';
import langHtml from '../../../assets/img/lang-html.png';
import langCss from '../../../assets/img/lang-css.png';
import langJs from '../../../assets/img/lang-js.png';
import langTs from '../../../assets/img/lang-ts.png';
import modDjango from '../../../assets/img/module-django.png';
import modNodejs from '../../../assets/img/module-nodejs.png';
import modSpring from '../../../assets/img/module-spring.png';
import modSklearn from '../../../assets/img/module-sklearn.png';
import libReactjs from '../../../assets/img/lib-reactjs.png';
import libOpencv from '../../../assets/img/lib-opencv.png';
import libPytorch from '../../../assets/img/lib-pytorch.png';
import libTensorflow from '../../../assets/img/lib-tensorflow.png';
import { getAnalytics, logEvent } from 'firebase/analytics';


function SkillStack() {
    const analytics = getAnalytics();

    return (
        <div id="skillstack-wrap">
            <div className="skillstack-container">
                <div className="lang-container">
                    <img src={langC} alt="c" />
                    <img src={langJava} alt="java" />
                    <img src={langPython} alt="python" />
                    <img src={langHtml} alt="html" />
                    <img src={langCss} alt="css" />
                    <img src={langJs} alt="js" />
                    <img src={langTs} alt="ts" />
                </div>
                <div className="mod-container">
                    <img className="django-img" src={modDjango} alt="django" />
                    <img className="nodejs-img" src={modNodejs} alt="nodejs" />
                    <img className="spring-img" src={modSpring} alt="spring" />
                    <img className="sklearn-img" src={modSklearn} alt="sklearn" />
                </div>
                <div className="lib-container">
                    <img src={libReactjs} alt="reactjs" />
                    <img src={libOpencv} alt="opencv" />
                    <img src={libPytorch} alt="pytorch" />
                    <img src={libTensorflow} alt="tensorflow" />
                </div>
                <div className="tags-container">
                    <div className="tags-row">
                        <ul>
                            <li>머신러닝</li>
                            <li>딥러닝</li>
                            <li>Java 백엔드</li>
                            <li>Python</li>
                            <li>알고리즘</li>
                        </ul>
                    </div>
                    <div className="tags-row">
                        <ul>
                            <li>프론트엔드</li>
                            <li>Django</li>
                            <li>코딩테스트</li>
                            <li>Data분석</li>
                        </ul>
                    </div>
                </div>

                <button id="ask-lecture-btn" type="button"
                    onClick={() => {
                        location.href='/mail';
                        logEvent(analytics, 'select_content', {
                            'event_category': '메일화면',
                            'event_action': '버튼클릭',
                            'event_label': '버튼2:강의의뢰/1:1문의하기'
                        });
                    }}>
                    강의 의뢰 / 1:1 문의하기
                </button>
            </div>
        </div>
    );
}

export default SkillStack;
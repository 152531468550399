import React from 'react';
import Header from './pages/mainapp/Header';
import Main from './pages/mainapp/Main';
import Footer from './pages/mainapp/Footer';
import Feynman from './components/Feynman'


function MainApp() {
  return (
    <div className="MainApp">
      <Header></Header>
      <Main></Main>
      <Footer></Footer>    
      <Feynman></Feynman>
    </div>
  )
}

export default MainApp

import React, { useEffect, useState } from 'react';
import './Career.css';
import careerZerobase from '../../../assets/img/career-zerobase.png';
import careerFastcampus from '../../../assets/img/career-fastcampus.png';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Career() {
    const [paperVis, setPaperVis] = useState({visibility: 'hidden'});
    const [careerTime, setCareerTime] = useState('UPDATING...');
    const analytics = getAnalytics();

    useEffect(() => {
        const updateCareerTime = () => {
            const startTime = new Date("2019-04-01");
            const todayTime = new Date();
            
            const diff = todayTime - startTime;
            
            const diffYear = Math.floor(diff / (1000*60*60*24*30*12));
            const diffMonth = Math.floor(diff / (1000*60*60*24*30) % 12);
            const diffDay = Math.floor(diff / (1000*60*60*24) % 30);
            const diffHour = Math.floor((diff / (1000*60*60)) % 24);
            const diffMin = Math.floor((diff / (1000*60)) % 60);
            const diffSec = Math.floor(diff / 1000 % 60);
            
            setCareerTime(`${diffYear}년 ${diffMonth}개월 ${diffDay}일 ${diffHour}시간 ${diffMin}분 ${diffSec}초`);
        }

        updateCareerTime();

        const timer = setInterval(() => {
            updateCareerTime();
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="career-container">
            <h2>경력/학력</h2>
            <div className="career-item">
                <h3>프로그래밍 강사 (2019. 04~)</h3>
                <div className="career-timer-container">
                    경력
                    <span id="career-timer">
                        {careerTime}
                    </span>
                </div>
                <div className="company-wrap">
                    <ul className="company">
                        <li>
                            <div>
                                <div className="img-container">
                                    <div className="status">현</div>
                                    <img className="zerobase" src={careerZerobase} alt="zerobase" />
                                </div>
                                <div className="desc">
                                    데이터 사이언스 / 프로그래밍 강사
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="img-container">
                                    <div className="status">전</div>
                                    <img src={careerFastcampus} alt="fastcampus" />
                                </div>
                                <div className="desc">
                                    딥러닝 / 머신러닝 / 프로그래밍 전임강사
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="img-container">

                                    <div className="custom">
                                        전 ‘L’기업
                                    </div>
                                </div>
                                <div className="desc">
                                    사내 딥러닝 자문 포지션
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="img-container">
                                    <div className="custom"></div>
                                </div>
                                <div className="desc">
                                    기타 기업 강의 출강
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="career-item">
                <h3>Software 연구원</h3>
                <div className='proj-container'>
                    <div className="custom">전 ‘L’기업</div>
                    <ul className='proj-list'>
                        <li>임베디드 차량 카메라 솔루션 개발</li>
                        <li>네트워크 카메라 기반 스마트팜 솔루션 개발</li>
                        <li>모바일 카메라 솔루션 개발</li>
                        <li>딥러닝/인공지능 코칭</li>
                    </ul>
                </div>
            </div>

            <div className="career-item">
                <h3>서강대학교 전자공학과 석사</h3>
                <div className="edu-special">
                    ** 2015 서강대학교 석사 최우수논문상 수상 (인공 신경망 분야)
                </div>
                <ul className="edu-list">
                    <li>서강대학교 전자공학과 학사 숨마쿰라우데 졸업</li>
                    <li>서울외국어고등학교 영어과 졸업</li>
                </ul>

                <button id='paper-btn' type="button"
                        onClick={() => {
                            setPaperVis({visibility: 'visible'});
                            logEvent(analytics, 'select_content', {
                                'event_category': '논문리스트',
                                'event_action': '버튼클릭',
                                'event_label': '버튼4:SCI 논문 리스트'
                            });
                        }}>
                    SCI논문 리스트
                </button>
            </div>

            <div id='paperlist-wrap' style={paperVis}>
            <div className="paperlist-container" >
                <h2>제 1저자</h2>
                <p>논문 노예를 소개합니다.<br />
                2년 동안 SCI 4편+별도의 졸업논문을 생산하였습니다.</p>
                <ul className="paperlist">
                    <li>
                        <a target="_blank" href="https://ieeexplore.ieee.org/abstract/document/7029601">
                            Histogram-based locality-preserving contrast enhancement
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://ietresearch.onlinelibrary.wiley.com/doi/full/10.1049/iet-ipr.2014.1014">
                            New interface for musical instruments using lip reading

                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://ietresearch.onlinelibrary.wiley.com/doi/full/10.1049/iet-ipr.2015.0692">
                            Power-constrained contrast enhancement for organic light-emitting diode display using locality-preserving histogram equalisation
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://ietresearch.onlinelibrary.wiley.com/doi/full/10.1049/iet-ipr.2016.0600">
                            Contrast-aware power control method for mobile active-matrix organic light-emitting diode display
                        </a>
                    </li>
                </ul>
                <h2>공저</h2>
                <ul><li>
                <a target="_blank" href="https://www.academia.edu/12236023/JOINT_IMAGE_REGISTRATION_AND_EXAMPLE_BASED_SUPER_RESOLUTION_ALGORITHM">
                    JOINT IMAGE REGISTRATION AND EXAMPLE-BASED SUPER-RESOLUTION ALGORITHM
                </a>
                </li></ul>
                
                <h2>학회</h2>
                <ul>
                    <li>
                    <a target="_blank" href="https://ieeexplore.ieee.org/abstract/document/7066410">
                    Power-constrained contrast enhancement using locality-preserving histogram equalization
                </a>
                    </li>
                </ul>
                
                <button id='paperlist-close-btn'
                        onClick={() => {
                            setPaperVis({visibility: 'hidden'});
                        }}>
                    창닫기
                </button>
            </div>
        </div>
        </div>
    );
}

export default Career;
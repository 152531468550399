import React from 'react';
import './HeadLine.css';
import profileImage from '../../../assets/img/me-interview.png';

function HeadLine() {
    return (
    <div id="headline-wrap">
        <div className='headline-container'>
            <div className='name-container'>
                <h1 className='name-kor'>신제용</h1>
                <h1 className='name-eng'>Jeyong Shin</h1>
            </div>
            <p className='desc'>
                ‘준비하는 자’의 노력을 의미있게 만드는 ‘속 깊은 멘토’
            </p>
            <img src={profileImage} alt='profile image'></img>
        </div>
    </div>);
}

export default HeadLine;
import React from 'react';
import SkillStack from './main/SkillStack';
import Lecture from './main/Lecture';
import Career from './main/Career';
import Etc from './main/Etc';
import HorizontalLine from '../../components/HorizontalLine';

function Main() {
    return (
    <div className='Main'>
        <SkillStack></SkillStack>
        <Lecture></Lecture>
        <HorizontalLine></HorizontalLine>
        <Career></Career>
        <HorizontalLine></HorizontalLine>
        <Etc></Etc>
    </div>);
}

export default Main;
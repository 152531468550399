import React from 'react';
import Header from './pages/mailapp/Header';
import Main from './pages/mailapp/Main';

function MailApp() {
    return (
        <div className="MailApp">
            <Header></Header>
            <Main></Main>
        </div>
      );
}

export default MailApp;
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainApp from './MainApp';
import MailApp from './MailApp';
import LunchApp from './LunchApp';
import LectureApp from './LectureApp';

function App() {
    return (
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainApp></MainApp>}></Route>
            <Route path="mail/" element={<MailApp></MailApp>}></Route>
            <Route path="lunch/" element={<LunchApp></LunchApp>}></Route>
            <Route path="lecture/" element={<LectureApp></LectureApp>}></Route>
        </Routes>
        </BrowserRouter>
      );
}

export default App;
/* eslint-disable no-restricted-globals */
import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import './Footer.css';

function Footer() {
    const analytics = getAnalytics();

    return (
        <footer>
            <div className="footer-container">
                <div className="about">
                    ABOUT
                </div>
                <div className="contact">
                <p><em>신제용</em> | 개발 강사 | 서울시 마포구 | abel@even-my-dogs.com  </p>
                </div>
                <div className="about-text">
                <p>성실한 걸론 부족하고 잘해야 한다죠,</p>
                <p>저는 성실한 사람을 잘하는 사람으로 만들어드릴 수 있습니다, 반드시.</p>
                </div>
            
                <div className='bonus-btn-wrap'>
                    여기까지 읽어주셔서 감사합니다, 여기 선물입니다.
                    <div className="bonus-btn-container">
                        <div id="bonus-btn"
                            onClick={() => {                        
                                location.href='/lunch';
                                logEvent(analytics, 'select_content', {
                                    'event_category': '점심메뉴',
                                    'event_action': '버튼클릭',
                                    'event_label': '푸터:상황 별 직장인 점심 메뉴 추천'
                                });
                                }}>
                            상황 별 직장인 점심메뉴 추천
                        </div>
                        <span>
                        &lt;-click
                        </span>
            
                    </div>
                </div>
                <div className="copyright">
                ⓒ 이븐마이독스
                </div>
            </div>
        </footer>
      );
}

export default Footer;
/* eslint-disable no-restricted-globals */
import React from 'react';
import './Lecture.css';
import lectureFastcampus from '../../../assets/img/lecture-fastcampus.png';
import lectureZerobase from '../../../assets/img/lecture-zerobase.png';
import lectureProgrammers from '../../../assets/img/lecture-programmers.png';
import { getAnalytics, logEvent } from 'firebase/analytics';


function Lecture() {
    const analytics = getAnalytics();

    return (
    <div className="lecture-container">
        <h2>대표 강의 이력</h2>
        <ul className="fastcampus">
            <li>
                <div>
                    <div className="img-container">
                        <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    딥러닝/인공지능 올인원 패키지 : 딥러닝/인공지능
                    <div className="lecture-tag">
                    #머신러닝 #딥러닝
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="img-container">
                        <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    머신러닝 A to Z 올인원 패키지 : 머신러닝
                    <div className="lecture-tag">
                    #머신러닝
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                <div className="img-container">
                <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                </div>
                    <div className="lecture-title">
                    퀴즈처럼 풀면서 배우는 파이썬 머신러닝 300제+
                    <div className="lecture-tag">
                    #Data분석 #머신러닝
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                <div className="img-container">
                <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                </div>
                    <div className="lecture-title">
                    Java 백엔드 개발 스쿨 강의
                    <div className="lecture-tag">
                    #Java백엔드 #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                <div className="img-container">
                <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                </div>
                    <div className="lecture-title">
                    프론트엔드 개발 스쿨 강의
                    <div className="lecture-tag">
                    #프론트엔드 #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                <div className="img-container">
                <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                </div>
                    <div className="lecture-title">
                    Python 백엔드 개발 스쿨 강의
                    <div className="lecture-tag">
                    #Python #Django #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                <div className="img-container">
                <img src={lectureFastcampus} alt="fastcampus" className="lecture-company" />
                </div>
                    <div className="lecture-title">
                    코딩+알고리즘 완주반 멘토링
                    <div className="lecture-tag">
                    #Python #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
        </ul>

        <ul className="zerobase">
            <li>
            <div>
                <div className="img-container">
                    <img src={lectureZerobase} alt="zerobase" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    데이터 취업 스쿨 담임 교수
                    <div className="lecture-tag">
                    #Python #Data분석 #머신러닝 #딥러닝
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureZerobase} alt="zerobase" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    백엔드 스쿨 코딩테스트 출제 및 해설 강의
                    <div className="lecture-tag">
                    #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureZerobase} alt="zerobase" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    데이터 사이언스 스쿨 강의
                    <div className="lecture-tag">
                    #Data분석 #머신러닝 #딥러닝 #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
        </ul>

        <ul className="programmers">
            <li>
                <div>
                <div className="img-container">
                    <img src={lectureProgrammers} alt="programmers" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    PCCP 대진대학교 특강 출강
                    <div className="lecture-tag">
                    #Java #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureProgrammers} alt="programmers" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    PCCP 서경대학교 특강 출강
                    <div className="lecture-tag">
                    #Java #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureProgrammers} alt="programmers" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    PCCP 한신대학교 특강
                    <div className="lecture-tag">
                    #Python #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureProgrammers} alt="programmers" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    PCCE 동덕여자대학교 특강
                    <div className="lecture-tag">
                    #Python #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <img src={lectureProgrammers} alt="programmers" className="lecture-company" />
                    </div>
                    <div className="lecture-title">
                    PCCE 이화여자대학교 특강 출강
                    <div className="lecture-tag">
                    #Python #알고리즘 #코딩테스트
                    </div>
                    </div>
                </div>
            </li>
        </ul>

        <ul className="custom">
            <li>
                <div>
                <div className="img-container">
                    <div className="custom-lecture">기업 맞춤 강의</div>
                    </div>
                    <div className="lecture-title">
                    LG이노텍 DX인증제 데이터 분석 강의
                    <div className="lecture-tag">
                    #Python #Data분석 #머신러닝 #딥러닝
                    </div>
                    </div>
                </div>
                <div>
                <div className="img-container">
                    <div className="custom-lecture">기업 맞춤 강의</div>
                </div>
                    <div className="lecture-title">
                    LG이노텍 베트남지사 DX인증제 강의
                    <div className="lecture-tag">
                    #영어강의 #Python #Data분석 #머신러닝 #딥러닝
                    </div>
                    </div>
                </div>
            </li>
        </ul>

        <button id="lecture-btn" type="button"
            onClick={() => {
                location.href='/lecture';
                logEvent(analytics, 'select_content', {
                    'event_category': '강의목록보기',
                    'event_action': '버튼클릭',
                    'event_label': '버튼3:오픈 되어 있는 강의 보기'
                });
            }}>
            오픈 되어 있는 강의 보기
        </button>

    </div>
    );
}

export default Lecture;
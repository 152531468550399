/* eslint-disable no-restricted-globals */
import React from 'react';
import './Etc.css';
import feyCanDev from '../../../assets/img/fey-candev.png';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Etc() {
    const analytics = getAnalytics();

    return (
        <div className="etc-container">
            <h2>기타</h2>
            
            <div className='evenmydogs-container'>
                <div className='evenmydogs-logo'>
                    <div className="img-container">
                        <img src={feyCanDev} alt="feynman can dev" />
                    </div>
                    <div className="desc"><em>‘이븐마이독스 even-my-dogs’</em> owner</div>
                </div>
                <p>개발 툴이 필요한 직장인과 초보 개발자를 위한 플랫폼</p>
                <p>even-my-dogs (a.k.a 개발유치원) 개원 준비중</p>
            </div>

            <div className="character-container">
                <h3>성격 / 습성</h3>
                <p>#차분한 #친절한 #달변가 #개발강사</p>
                <p>#침착한 #꼼꼼한 #프리랜서 #마감의수호자 </p>
                <p>#개발자 #개인프로젝트 #환경셋업덕후 #게이머</p>
                <p>#시니컬한말투 #그렇지않은글투</p>
            </div>

            <div className="ask-container">
                <div className="ask-text">
                    <p>프로젝트 제안, 개발자 친구나 멘토가 필요해요, 개발 트렌드가 궁금해요</p>
                    <p>대학원에 가야할까요? 점심 뭐 먹을까요?</p>
                </div>
                <div className="email">
                    abel@even-my-dogs.com
                </div>

                <button id="askany-btn" type="button"
                    onClick={() => {
                        location.href='/mail';
                        logEvent(analytics, 'select_content', {
                            'event_category': '메일화면',
                            'event_action': '버튼클릭',
                            'event_label': '버튼5:무엇이든 물어보세요'
                        });
                    }}>
                    무엇이든 물어보세요
                </button>
            </div>
        </div>
    );
}

export default Etc;
import React from 'react';
import './Header.css';

function Header() {
    return (
    <div id="lunchapp-header-wrap">
        <div className='lunchapp-header'>
            <div className="line">
            <h1>잘 챙겨드세요, 다 먹고 살자고 하는거니까 🍚</h1>
            </div>
        </div>
    </div>);
}

export default Header;
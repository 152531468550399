import React from 'react';
import Header from './pages/lectureapp/Header';
import Main from './pages/lectureapp/Main';

function LectureApp() {
    return (
        <div className="LectureApp">
            <Header></Header>
            <Main></Main>
        </div>
      );
}

export default LectureApp;
import React from 'react';
import Header from './pages/lunchapp/Header';
import Main from './pages/lunchapp/Main';
import Footer from './pages/lunchapp/Footer';

function LunchApp() {
    return (
        <div className="LunchApp">
            <Header></Header>
            <Main></Main>
            <Footer></Footer>
        </div>
      );
}

export default LunchApp;
/* eslint-disable no-restricted-globals */
import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import './QnA.css';


function QnA() {
    const analytics = getAnalytics();

    return (
    <div className="qna-container">
        <ul className="questions">
            <p>"머신러닝과 딥러닝은 어떻게 다른가요?"</p>
            <p>"파이썬은 정말 배우기 쉬운가요?"</p>
            <p>"제가 코딩을 배우면 앞으로의 삶이 바뀌나요?"</p>
            <p>"직원들이 코딩을 배우면 앞으로의 회사가 바뀌나요?"</p>
        </ul>
        <div className="answer">
            궁금한 점을 물어보세요
            <a className="email" href='/mail'>
                abel@even-my-dogs.com
            </a>
        </div>
        <button id="ask-btn" type="button"
            onClick={() => {
                location.href='/mail';
                logEvent(analytics, 'select_content', {
                    'event_category': '문의화면',
                    'event_action': '버튼클릭',
                    'event_label': '버튼1:무엇이든물어보세요'
                });
            }}>
            무엇이든 물어보세요
        </button>
    </div>);
}

export default QnA;
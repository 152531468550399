/* eslint-disable no-restricted-globals */
import React from 'react';
import HorizontalLine from '../../components/HorizontalLine';
import logoFastcampus from '../../assets/img/lecture-fastcampus.png';
import logoZerobase from '../../assets/img/lecture-zerobase.png';
import posterMl300 from '../../assets/img/lecture-ml300.png';
import posterBeschool from '../../assets/img/lecture-beschool.png';
import posterDataschool from '../../assets/img/lecture-dataschool.png';
import './Main.css';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Main() {
    const analytics = getAnalytics();

    return (
    <div className='lectureapp-main'>
        <div className="lectureapp-item">
            <article>
                <div className="logo">
                    <img src={logoFastcampus} alt="fast campus" />
                </div>
                <div className="poster">
                    <img src={posterMl300} alt="ML300+" />
                </div>
                <div className="title">
                <a target='_blank'
                 href="https://fastcampus.co.kr/data_online_ml300"
                 rel="noreferrer">
                퀴즈처럼 풀면서 배우는 파이썬 머신러닝 300제+
                </a>
                </div>
            </article>
            </div>
            <HorizontalLine></HorizontalLine>
            <div className="lectureapp-item">
            <article>
                <div className="logo">
                    <img src={logoZerobase} alt="zerobase" />
                </div>
                <div className="poster">
                    <img src={posterBeschool} alt="BE School" />
                </div>
                <div className="title">
                <a target='_blank' href="https://zero-base.co.kr/category_dev_camp/school_BE">
                확실한 취업공식 백엔드 스쿨
                </a>
                </div>
            </article>
            
        </div>

        <HorizontalLine></HorizontalLine>
            <div className="lectureapp-item">
            <article>
                <div className="logo">
                    <img src={logoZerobase} alt="zerobase" />
                </div>
                <div className="poster">
                    <img src={posterDataschool} alt="DS School" />
                </div>
                <div className="title">
                <a target='_blank' href="https://zero-base.co.kr/category_data_camp/school_DS">
                AI 부트캠프 | 데이터 취업 스쿨
                </a>
                </div>
            </article>
            
        </div>

        <div className="lecture-end-container">
            <p>강의 제안은 언제나 환영입니다</p>
            <p>데이터사이언스, 백엔드, 프론트엔드,</p>
            <p>파이썬 기초, 알고리즘, 코딩테스트,</p>
            <p>그리고 기업 맞춤강의 등등</p>

            <button id="lecture-ask-btn" type="button"
                onClick={() => {
                    location.href='/mail';
                    logEvent(analytics, 'select_content', {
                        'event_category': '메일화면',
                        'event_action': '버튼클릭',
                        'event_label': '강의목록버튼:강의의뢰/1:1문의하기'
                    });
                }}>
                강의 의뢰 / 1:1 문의하기
            </button>
            <div className="back-btn-container">
                <a href="/" onClick={()=>{
                    logEvent(analytics, 'select_content', {
                        'event_category': '포트폴리오',
                        'event_action': '버튼클릭',
                        'event_label': '강의목록버튼:포트폴리오로 돌아가기'
                    });
                }}>포트폴리오로 돌아가기</a>
            </div>
        </div>
    </div>);
}

export default Main;
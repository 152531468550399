import React from 'react';
import './Header.css';

function Header() {
    return (
    <div id="lecture-header-wrap">
        <div className='lecture-header'>
            <h1>오픈 되어 있는 강의 보기</h1>
        </div>
    </div>  );
}

export default Header;
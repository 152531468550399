/* eslint-disable no-restricted-globals */
import React from 'react';
import './Feynman.css';
import imgFeynman from '../assets/img/fey-move.png';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Feynman() {
    const analytics = getAnalytics();

    return (
    <div className='feynman-container'
         onClick={() => {
            location.href='/mail';
            logEvent(analytics, 'select_content', {
                'event_category': '문의화면',
                'event_action': '버튼클릭',
                'event_label': '강아지버튼'
            });
        }}>
        <img src={imgFeynman} alt="my dog feynman" />
        <div className='forehead'>
        저희 집 개입니다<br />
        눈이 맞으셨군요
        </div>
        <div className='neck'>
            무엇이든 물어보세요
            <div className='email'>abel@even-my-dogs.com</div>
        </div>
    </div>);
}

export default Feynman;
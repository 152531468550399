/* eslint-disable no-restricted-globals */
import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import './Footer.css';

function Footer() {
    const analytics = getAnalytics();

    return (
        <footer className="lunch-footer">
            <div className="lunch-footer-container">
                <div className="lunch-end-container">
                    <div className="lunch-comment">
                        ✨ 그나저나, 제 팬이 되신 것을 축하합니다 🎉
                    </div>
                    <button id="lunch-ask-btn" type="button"
                        onClick={() => {
                            location.href = '/mail';
                            logEvent(analytics, 'select_content', {
                                'event_category': '메일화면',
                                'event_action': '버튼클릭',
                                'event_label': '점심버튼:저에게 무엇이든 물어보세요'
                            });
                        }}>
                        저에게 무엇이든 물어보세요
                    </button>
                </div>
                <div className="back-btn-container">
                    <a href="/" onClick={() => {
                        logEvent(analytics, 'select_content', {
                            'event_category': '포트폴리오',
                            'event_action': '버튼클릭',
                            'event_label': '점심버튼:포트폴리오로 돌아가기'
                        });
                    }}>포트폴리오로 돌아가기</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import React from 'react';
import './Header.css';

function Header() {
    return (
    <div id="mail-header-wrap">
        <div className='mail-header'>
            <h1>무엇이든 물어보세요</h1>
        </div>
    </div>);
}

export default Header;
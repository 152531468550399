import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import clipImage from '../../assets/img/paper-clip.png';
import './Main.css';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Main() {
    const form = useRef();
    const analytics = getAnalytics();

    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [userDomain, setUserDomain] = useState('');
    const [userDomainVis, setUserDomainVis] = useState(false);
    const [userDomainSel, setUserDomainSel] = useState('');
    const [ccId, setCcId] = useState('');
    const [ccDomain, setCcDomain] = useState('');
    const [ccDomainVis, setCcDomainVis] = useState(false);
    const [ccDomainSel, setCcDomainSel] = useState('');
    const [text, setText] = useState('');
    const [fileName1, setFileName1] = useState('5MB이하만 업로드 가능');
    const [fileName2, setFileName2] = useState('5MB이하만 업로드 가능');

    const handleUserNameChange = ({ target: { value } }) => setUserName(value);
    const handleUserIdChange = ({ target: { value } }) => setUserId(value);
    const handleUserDomainChange = ({ target: { value } }) => setUserDomain(value);
    const handleUserDomainSel = ({ target: { value } }) => { setUserDomainSel(value) };
    const handleCcIdChange = ({ target: { value } }) => setCcId(value);
    const handleCcDomainChange = ({ target: { value } }) => setCcDomain(value);
    const handleCcDomainSel = ({ target: { value } }) => { setCcDomainSel(value) };
    const handleTextChange = ({ target: { value } }) => setText(value);
    const handleFileName1Change = ({ target: { value } }) => setFileName1(value);
    const handleFileName2Change = ({ target: { value } }) => setFileName2(value);

    useEffect(() => {
        setUserDomainVis(userDomainSel == 'others');
        setCcDomainVis(ccDomainSel == 'others');
    }, [userDomainSel, ccDomainSel, fileName1, fileName2])

    const sendEmail = (e) => {
        e.preventDefault();

        logEvent(analytics, 'select_content', {
            'event_category': '메일전송완료',
            'event_action': '버튼클릭',
            'event_label': '전송하기'
        });
    
        emailjs.sendForm('service_418izid', 'template_hw4d5z3', form.current, 'wYseqwaDqlfTpVD5R')
          .then((result) => {
              console.log(result.text);
              alert('이메일이 전송되었습니다! :)')
          }, (error) => {
              console.log(error.text);
              alert('이메일이 전송에 실패했습니다. 직접 이메일을 보내주세요!')
          });
      };

    return (
        <div className='Main'>
            <form ref={form} onSubmit={sendEmail}>
                <div className="from-container">
                    <div className="user-name-container">
                        <label htmlFor="user-name">성함이나 별명 <span>(필수)</span></label>
                        <input type="text"
                            name="user-name"
                            value={userName}
                            placeholder='예쁜내이름'
                            className='email-id'
                            onChange={handleUserNameChange} />
                    </div>
                    <div className="user-email-container">
                        <label htmlFor="user-id">답변 받으실 E-mail <span>(필수)</span></label>
                        <input type="text"
                            name="user-id"
                            value={userId}
                            placeholder='my_cute_id'
                            className='email-id'
                            onChange={handleUserIdChange} />
                        <span className="at">@</span>
                        <input type="text"
                            name="user-domain"
                            id="user-domain"
                            className='email-domain'
                            style={userDomainVis ? {} : { display: 'none' }}
                            value={userDomain}
                            placeholder='example.com'
                            onChange={handleUserDomainChange} />
                        <select name="user-domain-sel" id="user-domain-sel"
                            onChange={handleUserDomainSel}>
                            <option value="gmail.com">gmail.com</option>
                            <option value="kakao.com">kakao.com</option>
                            <option value="even-my-dogs.com">even-my-dogs.com</option>
                            <option value="others">기타 (직접 입력)</option>
                        </select>
                    </div>
                    <div className="cc-email-container">
                        <label htmlFor="cc-id">참조 <span>(선택)</span></label>
                        <input type="text"
                            name="cc-id"
                            value={ccId}
                            placeholder="other's_id"
                            className='email-id'
                            onChange={handleCcIdChange} />
                        <span className="at">@</span>
                        <input type="text"
                            name="cc-domain"
                            id="cc-domain"
                            className='email-domain'
                            style={ccDomainVis ? {} : { display: 'none' }}
                            value={ccDomain}
                            placeholder='example.com'
                            onChange={handleCcDomainChange} />
                        <select name="cc-domain-sel" id="cc-domain-sel"
                            onChange={handleCcDomainSel}>
                            <option value="gmail.com">gmail.com</option>
                            <option value="kakao.com">kakao.com</option>
                            <option value="even-my-dogs.com">even-my-dogs.com</option>
                            <option value="others">기타 (직접 입력)</option>
                        </select>
                    </div>
                </div>

                <div className="to-container">
                    <label htmlFor="email-text">
                        <div>
                            To. 신제용 강사
                        </div>
                        <div className="email">
                            <a href="mailto:abel@eve-my-dogs.com">
                                abel@even-my-dogs.com
                            </a>
                        </div>
                        <div className="email-desc">
                            메일주소를 클릭하면<br />
                            Outlook으로 연결됩니다
                        </div>
                    </label>
                    <div className="text-container">
                        <textarea
                            name="email-text"
                            id="email-text"
                            placeholder="자주 묻는 질문
                            1. ____관련 강의 해주실 수 있으신가요?
                            2. 개발이란 무엇인가요
                            3. 비전공자인데 개발을 할 수 있을까요?
                            4. 곧 겨울인데, 귤과 전기장판을 이기고 개인프로젝트를 하러 책상에 앉을 동기는 어떻게 얻나요?
                            5. 지금 졸린데 잠을 자는게 좋을까요?"
                            value={text}
                            onChange={handleTextChange}
                        >
                        </textarea>
                        <div className="text-desc">
                            <p>무엇이든 물어보세요 :)</p>
                            <p>질문하시는 분이 누구신지 꼭 알려주세요! 맞춤형 답변을 드립니다.</p>
                        </div>
                    </div>
                </div>

                <div className="img-attach-container">
                    <div className='img-attach-col'>
                        <span>이미지 첨부 1</span>
                        <input id="img-attach-name-1"
                            value={fileName1}
                            className="img-name"
                            disabled>
                        </input>
                        <label htmlFor="img-attach-1">
                            <img src={clipImage} alt="+" />
                        </label>
                        <input type="file"
                            accept="image/*"
                            id="img-attach-1"
                            name="img-attach-1"
                            onChange={handleFileName1Change}
                            hidden />
                    </div>
                    <div className='img-attach-col'>
                        <span>이미지 첨부 2</span>
                        <input id="img-attach-name-2"
                            value={fileName2}
                            className="img-name"
                            disabled>
                        </input>
                        <label htmlFor="img-attach-2">
                            <img src={clipImage} alt="+" />
                        </label>
                        <input type="file"
                            accept="image/*"
                            id="img-attach-2"
                            name="img-attach-2"
                            onChange={handleFileName2Change}
                            hidden />
                    </div>

                    <button id="send-email-btn" type="submit"
                            value="Send">
                        전송하기
                    </button>
                    <div className="back-btn-container">
                        <a href="/" onClick={() => {
                            logEvent(analytics, 'select_content', {
                                'event_category': '포트폴리오',
                                'event_action': '버튼클릭',
                                'event_label': '메일버튼:포트폴리오로 돌아가기'
                            });
                        }}>포트폴리오로 돌아가기</a>
                    </div>
                </div>
            </form>
        </div>);
}

export default Main;